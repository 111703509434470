import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type * as ServiceWorkerScriptStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';

export class ServiceWorkerScriptState implements ServiceWorkerScriptStateModule.ServiceWorkerScriptState {
    static objectStoreDexieSchema: string = "&[appId+id], appId, id";

    appId!: string;
    id!: string;
    importmapEntry: IServiceWorkerImportMapEntry;

    constructor(options: {
        appId: string;
        id: string;
        importmapEntry: IServiceWorkerImportMapEntry;
    }) {
        this.appId = options.appId;
        this.id = options.id;
        this.importmapEntry = options.importmapEntry;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateServiceWorkerScriptState(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteServiceWorkerScriptState(this);
    }

    public async forceReload(): Promise<ServiceWorkerScriptState | null> {
        return await IndexedDbHandler.getServiceWorkerScriptStateFromIndexedDB(this.appId, this.id);
    }
}

export default ServiceWorkerScriptState;
